import React from 'react'
import { navigate } from 'gatsby'

import Fade from 'react-reveal/Fade'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Button from '../components/button'
import RoomDescSimple from '../components/roomDescSimple'
import RoomDescGallery from '../components/roomDescGallery'
import ImageIntersectionCasita from '../images/intersecci-n-6.svg'
import ImageIntersection2Casita from '../images/intersecci-n-8.svg'

import ImageIntersection from '../images/intersecci-n-6.svg'

import {
  VILLAS_ROOM,
  CASITAS,
  CASTLES,
  CASITAS_POOLS,
  NIDO_AMOR,
  DRAGON,
  ARIES,
  ALTIPLANO,
  ESCONDIDA,
  PALOMIR,
  CARIOCA,
  AZUL,
  SELVA,
  CANDELABROS,
  CHACMOOL,
} from '../constants'

const RoomsDetails = () => {
  const goToHostingPage = () => {
    navigate('/hospedaje')
  }

  return (
    <Layout
      gradient={{
        background: 'linear-gradient(to bottom, #202945, rgba(32, 41, 69, 0))',
      }}
    >
      <SEO title="Private Casita" />
      <div className="rooms-details__main-image">
        <header>
          <Fade top duration={2000}>
            <h1 className="main-image__header">Private villa, casita</h1>
            <h2 className="main-image__header main-image__header--smaller">
              &amp; castle options
            </h2>
            <h3 className="main-image__header main-image__header--smallest">
              with Careyes
            </h3>
          </Fade>
        </header>
        <Fade duration={3000} delay={300}>
          <div className="main-image__description">
            <p>
              Careyes es un complejo turístico privado único compuesto por una
              variedad de opciones de alojamiento coloridas y carismáticas que
              incluyen castillos marinos, villas privadas, casitas y bungalows.
            </p>
            {/* <p>
                        Ticket prices are priced separately from casitas, villas,
                        and castle options.
                </p> */}
          </div>
        </Fade>
        <Button onClick={goToHostingPage}>REGRESAR</Button>
      </div>

      <section className="rooms-details__content">
        {/* <Fade duration={2000}>
                <RoomDescGallery {...CASTLES} />
            </Fade> */}
        {/* <div className="backgoundIntersection">
          
                <RoomDescSimple {...VILLAS_ROOM} />
        
                
            <Fade duration={2000}>
                <RoomDescGallery {...CASITAS_POOLS} />
            </Fade>
                </div>
                <RoomDescSimple {...CASITAS} /> */}

        <div className="backgoundIntersection">
          <Fade duration={2000}>
            <RoomDescGallery {...NIDO_AMOR} />
          </Fade>

          <Fade duration={2000}>
            <RoomDescSimple {...CARIOCA} />
          </Fade>

          <Fade duration={2000}>
            <RoomDescGallery {...DRAGON} />
          </Fade>

          <Fade duration={2000}>
            <RoomDescSimple {...AZUL} />
          </Fade>
        </div>
        <Fade duration={2000}>
          <RoomDescGallery {...ARIES} />
        </Fade>
        <div className="backgoundIntersection">
          <Fade duration={2000}>
            <RoomDescSimple {...SELVA} />
          </Fade>
          <Fade duration={2000}>
            <RoomDescGallery {...ALTIPLANO} />
          </Fade>
        </div>
        <Fade duration={2000}>
          <RoomDescSimple {...CANDELABROS} />
        </Fade>
        <div className="backgoundIntersection">
          <Fade duration={2000}>
            <RoomDescGallery {...ESCONDIDA} />
          </Fade>
          <Fade duration={2000}>
            <RoomDescSimple {...CHACMOOL} />
          </Fade>
          <Fade duration={2000}>
            <RoomDescGallery {...PALOMIR} />
          </Fade>
        </div>
      </section>
      <img
        src={ImageIntersection}
        alt="decorator"
        className="nido-section__image-decorator"
      />
      <Fade>
        <section className="rooms-details__footer">
          {/* <p className="footer__section">
                    Maecenas sodales laoreet dui vitae imperdiet.  Vestibulum iaculis sem eu nulla vulputate, vestibulum finibus nisi volutpat. 
                    Cras finibus augue vel ex posuere, sit amet fermentum diam imperdiet. In et nibh ex. Sed faucibus arcu eu 
                    hendrerit vestibulum. Morbi eget turpis sed purus porta volutpat. Nam sit amet tincidunt magna.
                </p>*/}
          <img
            src={ImageIntersection2Casita}
            alt="decorator"
            className="nido-section__image-decorator-2"
          />
        </section>
        <img
          src={ImageIntersectionCasita}
          alt="decorator"
          className="nido-section__image-decorator"
        />
      </Fade>
    </Layout>
  )
}

export default RoomsDetails
