import React from "react"
import PropTypes from "prop-types"
import Slide from 'react-reveal/Slide'

import ImageGallery from "../components/imageGallery"

import ImageDollar from "../images/dollar-sign.svg"
import ImageCouch from "../images/couch.svg"

const RoomDescSimple = ({id, name, side, bedrooms, price, mainImage, roomImage}) => (
    <div className="room-description" id={id}>
        <div className="room-description__container-decorator"></div>
        <div className="room-description__mobile-gallery">
            <ImageGallery imageList={[mainImage[0], roomImage[0], mainImage[0], roomImage[0]]}/>
        </div>
        <Slide left>
            <img
                src={mainImage[0]}
                srcSet={`${mainImage[1]} 2x,
                        ${mainImage[2]} 3x`}
                className="room-description__main-image"
                alt="test"
            />
        </Slide>
        <div className={`room-description__content ${!(bedrooms && price) && 'room-description__content--image'}`}>
            <div className="content__header">
                <h1 className="room-description__name">{name}</h1>
                {side && <h3 className="room-description__name-side">{side}</h3>}
            </div>
            <div className="room-description__chips">
                {bedrooms && (<div className="room-description__chip">
                    <img src={ImageCouch} alt="habitacion" className="chip__image" />
                    <p className="chip__description">{bedrooms}</p>
                </div>)}
                {price && (<div className="room-description__chip">
                    <img src={ImageDollar} alt="precio" className="chip__image" />
                    <p className="chip__description">{price}</p>
                </div>)}
            </div>
            <Slide right>
                <img
                    src={roomImage[0]}
                    srcSet={`${roomImage[1]} 2x,
                                ${roomImage[2]} 3x`}
                    className="room-description__room-image"
                    alt="test"
                />
            </Slide>
        </div>
    </div>
)

RoomDescSimple.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    side: PropTypes.string,
    bedrooms: PropTypes.string,
    price: PropTypes.string,
    mainImage: PropTypes.array.isRequired,
    roomImage: PropTypes.array.isRequired,
}

export default RoomDescSimple