import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import { useTransition, animated } from 'react-spring'

import ArrowBack from "../images/arrow_back.svg";
import ArrowForward from "../images/arrow_forward.svg";

const TRANSITIONS = {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
}

const ImageGallery = ({ imageList, placeholderList}) => {
    const [indexImage1, setIndexImage1] = useState(0)
    const [indexImage2, setIndexImage2] = useState(1)
    const [indexImage3, setIndexImage3] = useState(2)
    const [images, setImages] = useState([])

    useEffect(() => {
        setImages(imageList.map(image => (
            ({ style }) => <animated.div style={{ ...style, backgroundImage: `url(${image})` }}></animated.div>
        )))
    }, [imageList]);

    const onClickBack = () => {
        setIndexImage1(state => state === 0 ? images.length - 1 : state - 1)
        setIndexImage2(state => state === 0 ? images.length - 1 : state - 1)
        setIndexImage3(state => state === 0 ? images.length - 1 : state - 1)
    }
    const onClickForward = () => {
        setIndexImage1(state => (state + 1) % images.length)
        setIndexImage2(state => (state + 1) % images.length)
        setIndexImage3(state => (state + 1) % images.length)
    }
    const transitions1 = useTransition(indexImage1, p => p, TRANSITIONS)
    const transitions2 = useTransition(indexImage2, p => p, TRANSITIONS)
    const transitions3 = useTransition(indexImage3, p => p, TRANSITIONS)

    if (!images.length) {
        return <div></div>
    }

    return (
        <div className="image-gallery">
            <div className="simple-trans-main">
                {transitions1.map(({ item, props, key }) => {
                    const Image = images[item]
                    return <Image key={key} style={props} />
                })}
            </div>
            <div className="simple-trans-second">
                {transitions2.map(({ item, props, key }) => {
                    const Image = images[item]
                    return <Image key={key} style={props} />
                })}
            </div>
            <div className="simple-trans-third">
                {transitions3.map(({ item, props, key }) => {
                    const Image = images[item]
                    return <Image key={key} style={props} />
                })}
            </div>
            {placeholderList && placeholderList.length > 0 && (
                <div className="image-gallery__placeholder-wrapper">
                    {placeholderList[indexImage1]}
                </div>
            )}
            <div className="image-gallery__button-group">
                <button className="image-gallery__button" onClick={onClickForward}>
                    <img src={ArrowBack} alt="back" />
                </button>
                <button className="image-gallery__button" onClick={onClickBack}>
                    <img src={ArrowForward} alt="forward" />
                </button>
            </div>
        </div>
    )
}

ImageGallery.propTypes = {
    imageList: PropTypes.array.isRequired,
    placeholderList: PropTypes.array,
}

export default ImageGallery