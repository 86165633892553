import React from "react"
import PropTypes from "prop-types"

import ImageGallery from "../components/imageGallery"

import ImageDollar from "../images/dollar-sign.svg"
import ImageCouch from "../images/couch.svg"
import image1 from "../images/grupo-216.svg"
import image2 from "../images/grupo-214.svg"
import image3 from "../images/trazado-563.svg"

const RoomDescGallery = ({ id, name, side, bedrooms, price, pool, tennis, food, imageList }) => (
    <div className="room-description room-description--gallery" id={id}>
        <div className="room-description__content">
            <div className="content__header">
                <h1 className="room-description__name">{name}</h1>
                {side && <h3 className="room-description__name-side">{side}</h3>}
            </div>
            <div className={`room-description__chips ${price.length > 1 && 'room-description__chips--column'} ${pool && tennis && food && 'room-description__chips--multiple'}`}>
                <div className="room-description__chip">
                    <img src={ImageCouch} alt="habitacion" className="chip__image" />
                    <p className="chip__description">{bedrooms}</p>
                </div>
                {pool && (
                    <div className="room-description__chip">
                        <img src={image2} alt="tennis" className="chip__image" />
                        <p className="chip__description">{pool}</p>
                    </div>
                )}
                {tennis && (
                    <div className="room-description__chip">
                        <img src={image3} alt="comida" className="chip__image" />
                        <p className="chip__description">{tennis}</p>
                    </div>
                )}
                {food && (
                    <div className="room-description__chip">
                        <img src={image1} alt="alberca" className="chip__image" />
                        <p className="chip__description">{food}</p>
                    </div>
                )}
                {price.map((priceItem, key) => (
                    <div className="room-description__chip" key={key}>
                        <img src={ImageDollar} alt="precio" className="chip__image" />
                        <p className="chip__description">{priceItem}</p>
                    </div>
                ))}
            </div>
        </div>
        <ImageGallery imageList={imageList}/>
    </div>
)

RoomDescGallery.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    side: PropTypes.string,
    bedrooms: PropTypes.string.isRequired,
    price: PropTypes.array.isRequired,
    imageList: PropTypes.array.isRequired,
}

export default RoomDescGallery